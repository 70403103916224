import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { Router } from "@angular/router";
import { AuthenticationService } from "./services";
import { ToastComponent } from "./shared/components/toast/toast.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "elise-docgen-frontend";
  isLoggedIn: boolean = true;
  accessForbiden: boolean = false;

  constructor(
    private translate: TranslateService,
    private keycloakService: KeycloakService,
    private router: Router,
    public authService: AuthenticationService,
    public toast: ToastComponent
  ) {
    translate.setDefaultLang("en");
  }

  ngOnInit() {
    this.keycloakService.getToken().then((data) => {
      // this.authService.login(data).subscribe(() => {
      // }
      // );
    });
  }

  // Changing language on button press
  changeLanguage(language: string) {
    this.translate.setDefaultLang(language);
  }
}
