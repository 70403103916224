import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TopBarComponent } from "./components/top-bar/top-bar.component";
import { SideBarComponent } from "./components/side-bar/side-bar.component";
import { ToastComponent } from "./shared/components/toast/toast.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatExpansionModule } from "@angular/material/expansion";
import { AvatarModule } from "ngx-avatar";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  MatTreeModule,
  MatButtonModule,
  MatNativeDateModule,
  MatIconModule,
  MatSidenavModule,
  MatCardModule,
  MatListModule,
  MatToolbarModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatTooltipModule,
} from "@angular/material";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { initializeKeycloak } from "./shared/utils/app.init";
import { AuthenticationService } from "./services";
import { AuthenticationInterceptor } from "./interceptor/authentication.interceptor";
import { DocumentTemplateModule } from "./components/document-template/document-template.module";

// Import all Froala Editor plugins.
import "froala-editor/js/plugins.pkgd.min.js";

// Import a single Froala Editor plugin.
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins.pkgd.min.js";

// Import a Froala Editor language file.
import "froala-editor/js/languages/de.js";

// Import a third-party plugin.
import "froala-editor/js/third_party/font_awesome.min";
import "froala-editor/js/third_party/image_tui.min";
import "froala-editor/js/third_party/spell_checker.min";
import "froala-editor/js/third_party/embedly.min";
import { TenantConfigurationService } from "./services/tenant-configuration.service";
import { ConvertTimezonePipe } from './pipes/convert-timezone.pipe';
import { TableViewComponent } from "./shared/components/table-view/table-view.component";

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    SideBarComponent,
    ToastComponent,
    // ConvertTimezonePipe,
    // TableViewComponent
  ],
  imports: [
    BrowserModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    MatTreeModule,
    MatButtonModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatListModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatIconModule,
    MatExpansionModule,
    AvatarModule,
    FormsModule,
    HttpClientModule,
    DocumentTemplateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    KeycloakAngularModule,
    ReactiveFormsModule,
  ],
  exports: [DocumentTemplateModule],
  providers: [
    AuthenticationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, TenantConfigurationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    ToastComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
